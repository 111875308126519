.container {
  background-size: cover;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  min-height: 100vh;
  background-color: var(--mui-palette-bg-primary);
  padding-bottom: 90px;
}

.contentContainer {
  width: 100%;
  height: 100%;
  background-color: var(--mui-palette-bg-primary);
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  max-width: 700px;
  margin: auto;
}

.innerWrapper {
  max-width: 600px;
  margin: auto;
  height: 100%;
  padding: 70px 15px 15px;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: calc(100vh - 70px);
}