/* vipname is not using,so color here can ignore */
.vipIcon {
  height: auto;
  margin: auto;
}
.vipName {
  color: #fde266;
  font-size: 13;
  font-weight: 600;
}
.vipItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.level0 {
  color: #d8c29f;
}

.level1 {
  color: #ffb27d;
}

.level2 {
  color: #e0ecff;
}

.level3 {
  color: #fde266;
}

.level4,
.level5,
.level6,
.level7,
.level8,
.level9 {
  color: #9df2ff;
}

.level10 {
  color: #ffb8f6;
}

/* .vipIcon0 {
  width: 85%;
  height: 85%;
} */
