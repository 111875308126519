.title {
    font-weight: bold;
    color: var(--mui-palette-text-primary);
    font-size: 1.1rem;
    margin: 10px 0;
}

.link {
    background-color: var(--mui-palette-bg-fifth);
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 7px auto 15px;
    height: 38px;
}

.link p {
    color: var(--mui-palette-text-tertiary);
    font-size: var(--text-base);
    text-align: left;
    word-break: break-all;
    margin: 5px 15px 5px 0px;
}


.details {
    background-color: var(--mui-palette-bg-fifth);
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 4px;
    margin: 7px auto 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: var(--mui-palette-text-tertiary);
}

.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.copyLink {
    width: 20px;
    height: 20px;
}

.contactCS {
    width: 100%;
}


.usdtQrImg {
    width: 100%;
    height: auto;
}