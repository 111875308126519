.vaultContainer {
  display: flex;
  flex-direction: column;
}

.text {
  color: var(--mui-palette-text-tertiary);
  font-size: 0.875rem;
  margin-bottom: 10px;
}

.numFormat {
  color: var(--mui-palette-text-warning);
  font-weight: 600;
  font-size: var(--text-xl);
  margin-bottom: 25px;
}

.allBtn {
  color: var(--mui-palette-text-tertiary);
  font-weight: 700;
}

.btn {
  display: flex;
  margin: 20px auto;
  width: 90%;
}

.passwordContainer {
  position: relative;
  top: 50%;
  left: 50%;
  z-index: 300;
  transform: translate(-50%, -50%);
  outline: 0;
  max-width: 474px;
}