.button {
  font-weight: 600;
  height: 40px;
  font-family: 'OpenSans', sans-serif;
}

.iconStart {
  display: inline-flex;
  align-items: center;
}
.endIcon {
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
}