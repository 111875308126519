.container {
  border-radius: 8px;
  width: 100%;
  background-color: var(--mui-palette-bg-secondary);
  box-sizing: border-box;
}

.marginTop {
  margin-top: 20px;
}

.depositMarginTop {
  margin-top: 10px;
}

.title {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  /* background-color: #0E122A; */
  font-size: 16px;
  color: var(--mui-palette-text-primary);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  font-weight: 600;
}

.tilePadding {
  padding: 10px 15px;
  border-bottom: 0.5px solid var(--mui-palette-bg-fifth);
}

.depTilePadding {
  padding: 8px 12px;
}

.withoutBorder {
  border-bottom: 0.5px solid transparent;
}

.innerWrapper {
  box-sizing: border-box;
  min-height: 100px;
  padding: 15px 25px;
  width: 100%;
  position: relative;
  /* border: 0.5px solid #2E323D; */
}

.innerWrapperDep {
  box-sizing: border-box;
  /* min-height: 100px; */
  padding: 10px;
  width: 100%;
  position: relative;
}

.startIcon {
  width: 18px;
  height: 18px;
  border-radius: 25px;
  background-color: var(--mui-palette-bg-commonFrameNumBg);
  text-align: center;
  line-height: 18px;
  color: var(--mui-palette-text-black);
  margin-right: 8px;
  font-size: var(--text-sm);
  padding-top: 1px;
}

@media only screen and (width <=400px) {
  .innerWrapper {
    padding: 15px;
  }
}