.header {
  position: fixed;
  width: 100%;
  z-index: 100;
  text-align: center;
  height: 60px;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  padding: 0 14px;
  box-sizing: border-box;
  background-color: var(--mui-palette-bg-navBg);
  max-width: 700px;
  margin: auto;
}

.justifyCenter {
  justify-content: center;
}

.headerWithTitle {
  position: fixed;
  width: 100%;
  z-index: 100;
  text-align: center;
  top: 0px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0 14px;
  box-sizing: border-box;
  background-color: var(--mui-palette-bg-navBg);
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 700px;
  margin: auto;
}

.logo {
  height: auto;
  margin-top: 5px;
}

.backArrow {
  top: 19px !important;
}

.title {
  font-weight: 600;
  font-size: var(--text-xl);
  align-items: start;
  color: var(--mui-palette-text-primary);
  margin-left: 40px;
}

.leftHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.leftHeader a {
  display: block;
  /* height: 30px; */
}

.closeContainer {
  position: absolute;
  right: 20px;
  top: 22px;
}

.hasTop {
  top: 45px;
}

.noneTop {
  top: -1px;
}

.navLink {
  margin: 5px 10px 0px 0px;
}