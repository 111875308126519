@font-face {
  font-family: 'OpenSans', sans-serif;
  src: url('../../../../../common/assets/fonts/OpenSans-Regular.ttf') format('truetype');
  /* src: url('../../../../../common/assets/fonts/SF-Pro-Display-Regular.ttf') format('truetype'); */
}

.footerWrapper {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 20;
  height: 65px;
}

.footer {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: auto;
  max-width: 700px;
  /* padding: 0 10px; */
  position: relative;
}

.navLink {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 24px;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.footerNavLeft {
  z-index: 1;
  width: 100%;
  /*border-top-right-radius: 15px;*/
  /* box-shadow: 0px 6px 7px 0px rgba(23, 23, 23, 0.28); */
  background: var(--mui-palette-bg-navBg);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 3% 0 2%;
}

.footerNavRight {
  width: 100%;
  /*border-top-left-radius: 15px;*/
  /* box-shadow: 15px 4px 15px 0px rgba(23, 23, 23, 0.28); */
  background: var(--mui-palette-bg-navBg);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2% 0 3%;
}

.footerNavFab {
  width: 9rem;
  height: 100%;
  background: transparent;
  border: none;
  display: flex;
}

.footerNavTop {
  width: 100%;
  height: 45%;
  border-bottom-left-radius: 10rem;
  border-bottom-right-radius: 10rem;
  justify-content: center;
  background-color: transparent;
  box-shadow: 0px 35px 0px 30px var(--mui-palette-bg-navBg);
  display: flex;
}
.footerNavFabIconDiv {
  position: relative;
  border-radius: 50%;
  background: var(--mui-palette-component-footer-footerIcon);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 22px;
  width: 48px;
  height: 48px;
  top: -25px;
}

.footerNavFabIconDiv::before {
  content: '';
  position: absolute;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: var(--mui-palette-bg-navBg);
  z-index: -1;
  box-shadow: 0 0 0 3px var(--mui-palette-btn-btnSecondary);
}

.footerNavFabIcon {
  width: 55px;
  height: auto;
}

.tab,
/* .aviatortab, */
.appIconTab {
  /* color: rgba(255, 255, 255, 0.4); */
  color: var(--mui-palette-text-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: var(--text-sm);
  font-weight: 600;
}

.appIconTab div {
  bottom: 2px;
  position: relative;
  margin-top: 2px;
}

.tab div {
  margin-top: 3px;
  text-align: center;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  /* letter-spacing: 1px; */
}

/* .aviatortab div {
  margin-top: 4px;
  text-align: center;
} */

.aviator div {
  /* color: #ff003c; */
  color: var(--mui-palette-text-secondary);
  /* font-weight: 700; */
}

/* .aviator svg {
   margin-bottom: -10px;
} */

:global(.active) .tab div {
  color: var(--mui-palette-text-white);
  font-weight: 700;
}

:global(.active) .appIconTab div {
  font-weight: 700;
}

:global(.active) .aviatortext {
  font-weight: 700;
}

.aviatortextDiv {
  margin-top: 5px;
}

.aviatortext {
  color: var(--mui-palette-text-white);
  font-size: 0.8rem;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  font-weight: 600;
}

.appIcon {
  -webkit-animation: shockwaveJump 1s ease-out infinite;
  animation: shockwaveJump 1s ease-out infinite;
  border-radius: 6px;
}

.appIcon::before {
  content: '';
  position: absolute;
  width: 22px;
  height: 22px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 6px;
  -webkit-animation: shockwave 1s 0.5s ease-out infinite;
  animation: shockwave 1s 0.5s ease-out infinite;
}

.appIcon::after {
  content: '';
  position: absolute;
  width: 22px;
  height: 22px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 6px;
  -webkit-animation: shockwave 1s 0.5s ease-out infinite;
  animation: shockwave 1s 0.5s ease-out infinite;
}

@keyframes shockwave {
  0% {
    transform: scale(1);
    box-shadow: 0 0 2px #E3A900, inset 0 0 1px #E3A900;
  }

  95% {
    box-shadow: 0 0 8px #E3A900, inset 0 0 8px #E3A900;
  }

  100% {
    transform: scale(1.3);
  }
}

@keyframes shockwaveJump {
  0% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.08);
  }

  50% {
    transform: scale(0.98);
  }

  55% {
    transform: scale(1.02);
  }

  60% {
    transform: scale(0.98);
  }

  100% {
    transform: scale(1);
  }
}