.amtWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0 0;
}

.amtRow, .winLossRow {
  display: flex;
  /*gap: 4px;*/
  align-items: center;
  color: var(--mui-palette-text-secondary);
  /*background: var(--mui-palette-bg-action3);*/
  /*-webkit-background-clip: text;*/
  /*background-clip: text;*/
  /*-webkit-text-fill-color: transparent;*/
  font-size: var(--text-md);
  margin-bottom: 12px;
  font-weight: 700;
}


.amtRow span,
.winLossRow span {
  font-size: var(--text-md);
  font-weight: 700;
}

.winLossRow {
  display: flex;
  /*gap: 4px;*/
  align-items: center;
  color: var(--mui-palette-text-secondary);
  font-size: var(--text-md);
  margin-bottom: 12px;
}

.recordList {
  max-height: 60vh;
  overflow-y: auto;
  margin-bottom: 90px;
}

@media only screen and (height > 1023px) {
  .recordList {
    max-height: 550px !important;
  }
}

@media only screen and (height < 668px) {
  .recordList {
    max-height: 50vh;
  }
}

@media only screen and (width > 400px) {

  .amtRow span,
  .winLossRow span {
    font-size: var(--text-xl);
  }
}

@media only screen and (width > 700px) {

  .amtRow span,
  .winLossRow span {
    font-size: 18px;
  }
}

.coin {
  margin-left: 5px;
}