.container {
  width: 100%;
  height: 100%;
  background-color: var(--mui-palette-bg-primary);
  background-size: 100% auto;
  background-repeat: no-repeat;
  color: var(--mui-palette-text-secondary);
}

.title {
  color: var(--mui-palette-text-primary);
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  margin-left: 3px;
}

.dateRowWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dateRow {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
}

.date {
  width: 45%;
}

.to {
  color: var(--mui-palette-text-secondary);
  height: 46px;
  line-height: 35px;
}

.search {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  border: 1px solid #2d346e;
  border-radius: 4px;
  justify-content: center;
}
