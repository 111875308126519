.box {
  background-color: var(--mui-palette-bg-secondary);
  width: 100%;
  height:150px;
  box-sizing: border-box;
  color: var(--mui-palette-text-tertiary);
  border-radius: 6px;
  margin-bottom: 12px;
}

.dateRow {
  box-sizing: border-box;
  padding: 10px 14px 10px;
  border-bottom: 0.5px solid var(--mui-palette-border-quaternary);
  font-size: var(--text-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.downPart {
  padding: 10px 14px 17px;
}

.num {
  display: block;
  color: var(--mui-palette-text-primary);
  font-weight: 700;
  font-size: 18px;
  margin-top: 9px;
}

.payIcon {
  height: 35px;
}

.id {
  font-size: var(--text-base);
  position: relative;
  display: flex;
  color: var(--mui-palette-text-secondary);
}

.idRight {
  position: absolute;
  right: 2px;
}

.status {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.status div {
  margin-right: 6px;
}

.game {
  color: var(--mui-palette-text-primary);
}

.betResult {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 9px;
  gap: 5px;
}

.adjustmentResult {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.betRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin: 5px 0px;
}

.label {
  font-size: var(--text-base);
}

.betNum {
  color: var(--mui-palette-text-primary);
  font-size: var(--text-base);
  font-weight: 400;
}

.betResultLabel {
  padding: 6px;
  min-width: 45px;
  border: 2px solid;
  border-radius: 6px;
  font-size: var(--text-base);
  font-weight: 500;
  text-align: center;
}

.green {
  color: var(--mui-palette-text-success2);
  border-color: var(--mui-palette-text-success2);
}

.orange {
  color: var(--mui-palette-text-warning);
  border-color: var(--mui-palette-text-warning);
}

.red {
  color: var(--mui-palette-text-danger2);
  border-color: var(--mui-palette-text-danger2);
}

.depositChannel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.depositChannel img {
  margin-right: 10px;
}

.depositChannel div {
  font-size: var(--text-sm);
}

.bonusRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyLink {
  width: 20px;
  height: 20px;
}

@media only screen and (width > 400px) {
  .id,
  .label,
  .betNum,
  .betResultLabel {
    font-size: var(--text-md);
  }

  .betResultLabel {
    min-width: 60px;
  }
}

@media only screen and (width > 700px) {
  .betResultLabel {
    min-width: 85px;
  }
}
