.header {
  width: 100%;
  height: 100%;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--mui-palette-bg-navBg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: fixed;
  max-width: 700px;
  margin: auto;
  z-index: 999;
}

.logo {
  /* margin-bottom: 16px; */
  height: auto;
}

.closeContainer {
  position: absolute;
  top: 22px;
  right: 20px;
}

.backArrow {
  top: 18px !important;
}