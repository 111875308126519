.dateRowWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dateRow {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
}

.date {
  width: 45%;
}

.to {
  color: var(--mui-palette-text-secondary);
  height: 46px;
  line-height: 35px;
}

.search {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  border: 1px solid var(--mui-palette-bg-tertiary);
  border-radius: 4px;
  justify-content: center;
}
