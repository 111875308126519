.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  color: var(--mui-palette-text-secondary);
  padding-top: 10px;
}

.noDataTxt {
  padding-top: 5px;
}